/* Copyright 2013 - 2022 Waiterio LLC */
import React from 'react'
import getCookie from '@monorepo/shared/getCookie.js'
import blogId from '@authrice/shared/blogId.js'
import domainProduction from '@authrice/shared/domainProduction.js'
import facebookHandle from '@authrice/shared/facebookHandle.js'
import linkedinHandle from '@authrice/shared/linkedinHandle.js'
import getAppUrl from '@authrice/shared/getAppUrl.js'
import NavigationComponent from '@stiloso/components/NavigationComponent.js'
import BannerSection from '@stiloso/sections/BannerSection.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconBrand from '@authrice/shared/IconBrand.js'
import WhySection from '@stiloso/sections/WhySection.js'
import FactsSection from '@stiloso/sections/FactsSection.js'
import HowSection from '@stiloso/sections/HowSection.js'
import QuestionsSection from '@stiloso/sections/QuestionsSection.js'
import BlogSection from '@stiloso/sections/BlogSection.js'
import TestimonialSection from '@stiloso/sections/TestimonialSection.js'
import IconCart from '@stiloso/icons/IconCart.js'
import IconCloudSearch from '@stiloso/icons/IconCloudSearch.js'
import IconPapillon from '@stiloso/icons/IconPapillon.js'
import IconPolyblog from '@stiloso/icons/IconPolyblog.js'
import IconTranslate from '@stiloso/icons/IconTranslate.js'
import IllustrationInformation from '@stiloso/components/IllustrationInformation.js'
import IllustrationLogin from '@stiloso/components/IllustrationLogin.js'
import IllustrationManWorkingOnComputer from '@stiloso/components/IllustrationManWorkingOnComputer.js'
import IllustrationTranslate from '@stiloso/components/IllustrationTranslate.js'
import product from '../product.js'
import FooterComponent from '../components/FooterComponent.js'
import languages from '../languages.js'

export const Head = ({ locale }) => {
  const { t } = useTranslation(locale)

  const title = t('__brand__', product)
  const bannerTitle = t('__home_banner_title__')
  const bannerSubtitle = t('__home_banner_subtitle__')
  const description = `${bannerTitle} | ${bannerSubtitle}`
  const titleSEO = t('__home_head_title__', `${description} | ${title}`)
  const descriptionSEO = t('__home_head_meta_description__', description)

  return (
    <>
      <title>{titleSEO}</title>
      <meta name="description" content={descriptionSEO} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {/* <meta name="twitter:image" content={workingOnComputerIcon} /> */}
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={`https://www.${domainProduction}/`} />
      {/* <meta property="og:image" content={workingOnComputerIcon} /> */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      {/* <meta itemProp="image" content={workingOnComputerIcon} /> */}
      <link
        rel="canonical"
        href={`https://www.${domainProduction}/${locale}/`}
      />
      <link
        rel="alternate"
        href={`https://www.${domainProduction}/en/`}
        hreflang="x-default"
      />
      {languages.map(language => (
        <link
          key={language}
          rel="alternate"
          href={`https://www.${domainProduction}/${language}/`}
          hreflang={language}
        />
      ))}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            '@id': `https://www.${domainProduction}/${locale}/`,
            url: `https://www.${domainProduction}/${locale}/`,
            inLanguage: locale,
            isPartOf: {
              '@id': `https://www.${domainProduction}/${locale}/#website`,
            },
            author: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            publisher: {
              '@id': `https://www.${domainProduction}/#corporation`,
            },
            translationOfWork: {
              '@id': `https://www.${domainProduction}/en/#website`,
            },
            '@graph': [
              {
                '@type': 'WebSite',
                '@id': `https://www.${domainProduction}/${locale}/#website`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/${locale}/#website`,
                inLanguage: locale,
                author: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
                publisher: {
                  '@id': `https://www.${domainProduction}/#corporation`,
                },
              },
              {
                '@type': 'Corporation',
                '@id': `https://www.${domainProduction}/#corporation`,
                name: 'Waiterio',
                url: `https://www.${domainProduction}/#corporation`,
                sameAs: [
                  facebookHandle &&
                    `https://www.facebook.com/${facebookHandle}`,
                  linkedinHandle &&
                    `https://www.linkedin.com/company/${linkedinHandle}}`,
                ].filter(_ => _),
              },
            ],
          }),
        }}
      />
    </>
  )
}

export const paths = languages.map(locale => ({
  path: `/${locale}/`,
  props: {
    locale,
  },
}))

const HomePage = ({ locale }) => {
  const { t } = useTranslation(locale)
  const appUrl = getAppUrl()

  const whySubsections = [
    {
      title: t('__home_why_1_title__'),
      content: t('__home_why_1_content__'),
    },
    {
      title: t('__home_why_2_title__'),
      content: t('__home_why_2_content__'),
    },
    {
      title: t('__home_why_3_title__'),
      content: t('__home_why_3_content__'),
    },
    {
      title: t('__home_why_4_title__'),
      content: t('__home_why_4_content__'),
    },
  ]

  const facts = [
    {
      content: t('__home_facts_1__'),
      Icon: IconCart,
    },
    {
      content: t('__home_facts_2__'),
      Icon: IconTranslate,
    },
    {
      content: t('__home_facts_3__'),
      Icon: IconCloudSearch,
    },
  ]

  const howSubsections = [
    {
      title: t('__home_how_1_title__'),
      content: t('__home_how_1_content__'),
      Illustration: IllustrationLogin,
    },
    {
      title: t('__home_how_2_title__'),
      content: t('__home_how_2_content__'),
      Illustration: IllustrationInformation,
    },
    {
      title: t('__home_how_3_title__'),
      content: t('__home_how_3_content__'),
      Illustration: IllustrationTranslate,
    },
  ]

  const clients = [
    {
      name: 'Waiterio',
      logo: <IconPapillon width="44" height="44" style={{ fill: 'white' }} />,
      color: '#3f92d2',
      testimonial: t('__home_testimonials_1__'),
      url: 'https://www.waiterio.com',
    },
    {
      name: 'Polyblog',
      logo: <IconPolyblog width="44" height="44" style={{ fill: 'white' }} />,
      color: '#3329A3',
      testimonial: t('__home_testimonials_2__'),
      url: 'https://www.polyblog.io',
    },
  ]

  const documents = [
    {
      title: t('__home_faqs_1_title__'),
      content: t('__home_faqs_1_content__'),
    },
    {
      title: t('__home_faqs_2_title__'),
      content: t('__home_faqs_2_content__'),
    },
    {
      title: t('__home_faqs_3_title__'),
      content: t('__home_faqs_3_content__'),
    },
    {
      title: t('__home_faqs_4_title__'),
      content: t('__home_faqs_4_content__'),
    },
    {
      title: t('__home_faqs_5_title__'),
      content: t('__home_faqs_5_content__'),
    },
  ]

  let showDashboard = !!getCookie(product + 'AccessToken')

  return (
    <>
      <NavigationComponent
        locale={locale}
        AppIcon={<IconBrand light />}
        appUrl={getAppUrl()}
        appName={t('Authrice')}
        showDashboard={showDashboard}
        logoText
      />
      <BannerSection
        title={t('__home_banner_title__')}
        subtitle={t('__home_banner_subtitle__')}
        buttonLabel={t(showDashboard ? 'Go to the App' : 'Try it for free')}
        buttonOnClick={() => {
          window.location.href = showDashboard ? appUrl : `${appUrl}/signup`
        }}
      />
      <WhySection
        title={t('__home_why_title__')}
        subsections={whySubsections}
        illustration={<IllustrationManWorkingOnComputer />}
      />
      <FactsSection title={t('__home_facts_title__')} facts={facts} />
      <HowSection
        title={t('__home_how_title__')}
        subsections={howSubsections}
      />
      <TestimonialSection
        title={t('__home_testimonials_title__')}
        clients={clients}
      />
      <QuestionsSection
        title={t('__home_faqs_title__')}
        locale={locale}
        documents={documents}
      />
      <BlogSection blogId={blogId} locale={locale} />
      <FooterComponent locale={locale} />
    </>
  )
}

export default HomePage
