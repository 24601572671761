/* Copyright 2013 - 2022 Waiterio LLC */
import IS_DEVELOPMENT from './IS_DEVELOPMENT.js'
import IS_STAGING from './IS_STAGING.js'
import IS_TESTING from './IS_TESTING.js'
import IS_BROWSER from './IS_BROWSER.js'
import IS_NODE from './IS_NODE.js'

export default function getAuthriceAppUrl() {
  let url = 'https://app.authrice.com'

  if (IS_BROWSER && window.AUTHRICE_APP_URL === '{{{AUTHRICE_APP_URL}}}') {
    delete window.AUTHRICE_APP_URL
  }

  if (IS_NODE && process.env.AUTHRICE_APP_URL === '{{{AUTHRICE_APP_URL}}}') {
    delete process.env.AUTHRICE_APP_URL
  }

  if (IS_BROWSER && window.AUTHRICE_APP_URL) {
    url = `${window.AUTHRICE_APP_URL}`
  } else if (IS_NODE && process.env.AUTHRICE_APP_URL) {
    url = process.env.AUTHRICE_APP_URL
  } else if (IS_TESTING && process.env.AUTHRICE_APP_URL) {
    url = process.env.AUTHRICE_APP_URL
  } else if (IS_STAGING) {
    url = 'https://app.authrice-staging.com'
  } else if (IS_DEVELOPMENT || IS_TESTING) {
    // url = 'https://app.authrice-staging.com';
    // url = 'http://192.168.1.64:1616';
    url = 'http://localhost:1616'
  }

  return url
}
