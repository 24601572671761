/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import container from '@stiloso/styles/bootstrap/container.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'
import row from '@stiloso/styles/bootstrap/row.js'
import languages from '../languages.js'

export const paths = languages
  .map(locale => ({
    path: `/${locale}/not-found/`,
    props: {
      locale,
    },
  }))
  .concat({
    path: '*',
  })

const errorSection = css`
  padding: 100px 0;
  text-align: center;
`

const goBackToTheHomePage = css`
  background-color: var(--color-primary);
  color: white;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  line-height: 48px;
  padding: 0px 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  :hover {
    background-color: var(--color-primary-light);
  }
  :active {
    background-color: var(--color-primary-dark);
  }
`

const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <div css={errorSection}>
      <div css={container}>
        <div css={row}>
          <div css={colSm12}>
            <div
              css={{
                fontSize: 180,
                fontWeight: 700,
                lineHeight: '180px',
                color: '#333333',
              }}
            >
              404
            </div>
            <div
              css={{
                fontSize: 24,
                fontWeight: 600,
                lineHeight: '24px',
                margin: '20px 0px',
              }}
            >
              {t('Page not found')}
            </div>
            <a href="/" css={goBackToTheHomePage}>
              {t('Go back to the home page')}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFoundPage
