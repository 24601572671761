/* Copyright 2013 - 2022 Waiterio LLC */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import container from '@stiloso/styles/bootstrap/container.js'
import row from '@stiloso/styles/bootstrap/row.js'
import colMd12 from '@stiloso/styles/bootstrap/colMd12.js'
import colMd6 from '@stiloso/styles/bootstrap/colMd6.js'
import colLg6 from '@stiloso/styles/bootstrap/colLg6.js'
import colSm12 from '@stiloso/styles/bootstrap/colSm12.js'

const section = css`
  padding: 80px 0px;
  background-color: white;

  @media (max-width: 992px) {
    padding: 64px 0px;
  }

  @media (max-width: 768px) {
    padding: 48px 0px;
  }
`

const sectionHeading = css`
  font-size: 36px;
  text-transform: capitalize;
  font-weight: bold;
  color: white;
  text-align: left;
  color: var(--color-primary);
  margin-bottom: 48px;

  @media (max-width: 992px) {
    font-size: 28px;
    line-height: 32px;
    text-align: center;
  }
`

const subsectionContainer = css`
  ${colMd12}
  ${colLg6}

  @media(max-width: 992px) {
    order: 2;
    margin-top: 40px;
  }
`

const subsectionItem = css`
  ${colSm12}
  ${colMd6}

  @media(max-width: 992px) {
    :nth-of-type(n + 3) {
      margin-top: 16px;
    }
  }

  @media (max-width: 767px) {
    :not(:first-of-type) {
      margin-top: 16px;
    }
  }
`

const subsectionHeading = css`
  color: var(--color-primary);
  line-height: 28px;
`

const subsectionContent = css`
  font-size: 16px;
  color: #6a768a;
  line-height: 26px;
`

const imageContainer = css`
  ${colMd12}
  ${colLg6}

  @media (max-width: 992px) {
    order: 1;
  }
`

// const image = css`
//   width: 100%;
//   height: auto;
// `

const WhySection = ({ title, subsections, illustration }) => (
  <section id="why-use-section" css={section}>
    <div css={container}>
      <div css={row}>
        <div css={colMd12}>
          <h2 css={sectionHeading}>{title}</h2>
        </div>
      </div>
      <div css={row}>
        <div css={subsectionContainer}>
          <div css={row}>
            {subsections.map(({ title, content }, index) => (
              <div key={title || index} css={subsectionItem}>
                <h3 css={subsectionHeading}>
                  {index + 1}. {title}
                </h3>
                <p css={subsectionContent}>{content}</p>
              </div>
            ))}
          </div>
        </div>
        <div css={imageContainer}>{illustration}</div>
      </div>
    </div>
  </section>
)

export default WhySection
